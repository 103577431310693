/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	background-color: lightsteelblue;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@layer components {
	.menu-active {
		color: #fff;
	}
	.img-thumbnail {
		width: 200px;
		height: 100px;
		object-fit: contain;
	}
	.img-preview {
		width: 400px;
		height: 392px;
		object-fit: contain;
	}
	.img-robot-crop {
		width: 30px;
		height: 20px;
		object-fit: cover;
	}
	.img-crop {
		width: 288px;
		height: 196px;
		object-fit: cover;
	}
	.vertical-center {
		margin: 0;
		position: absolute;
		top: 50%;
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.center {
		margin: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	.crop-wrapper {
		position: relative;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.crop-container-large {
		position: relative;
		width: 592px;
		height: 592px;
	}
	.crop-preview-large {
		background-size: 518px 592px;
		background-image: url('https://ethemerals-media.s3.amazonaws.com/firebase_card_bg.jpg');
		position: relative;
		width: 518px;
		height: 592px;
	}
	.crop-container-preview {
		position: relative;
		width: 300px;
		height: 450px;
	}
	.crop-preview-preview {
		position: relative;
		width: 256px;
		height: 385px;
	}
	.crop-container-thumbnail {
		position: relative;
		width: 150px;
		height: 200px;
	}
	.crop-preview-thumbnail {
		position: relative;
		width: 74px;
		height: 74px;
	}
	.crop-container-inventory {
		position: relative;
		width: 500px;
		height: 500px;
	}
	.crop-preview-inventory {
		position: relative;
		width: 388px;
		height: 112px;
	}
	.rs-slider {
		width: 100%;
		height: 25px;
		background-color: #fff;
	}
	.rs-thumb {
		height: 25px;
		line-height: 25px;
		width: 25px;
		text-align: center;
		background-color: #fff;
		color: black;
		border-radius: 50%;
		cursor: grab;
	}
	.rs-track {
		top: 0;
		bottom: 0;
		background: '#f00';
		border-radius: 999px;
	}
	.nft_details_container {
		width: 1096px;
	}
	.nft_details_bg {
		width: 700px;
		height: 924px;
	}
	.nft_details_img {
		width: 700px;
		height: 800px;
	}
	.fade-in {
		animation: fadeIn ease 0.1s;
		animation-fill-mode: forwards;
	}
	@keyframes fadeIn {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
}
